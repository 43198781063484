import { useStaticQuery, graphql } from 'gatsby';

const restaurantPlanChoice = (data: Array<any> = [], restaurants: Array<string> = [], count = 3, skip = 0) => {
  const ary = data.filter((item) => {

    //レストランフィルター
    if (restaurants.length > 0) {
      if (!item.node.restaurants.some((el: string) => restaurants.includes(el))) {
        return false
      }
    }

    return true
  })
  return ary.slice(skip, count == 0 ? undefined : count)
}

export const restaurantPlanEventChoice = (data: Array<any> = [], restaurants: Array<string> = [], event: Array<string> = []) => {
  const ary = data.filter((item) => {

    //レストランフィルター
    if (restaurants.length > 0) {
      if (!item.node.restaurants.some((el: string) => restaurants.includes(el))) {
        return false
      }
    }

    //慶弔フィルター
    if (event.length > 0) {
      if (!item.node.eventclass.some((el: string) => event.includes(el))) {
        return false
      }
    }

    return true
  })
  return ary
}

export default restaurantPlanChoice
