import { useStaticQuery, graphql } from 'gatsby';

const restaurantPlanGet = () => {
  const newsdataquery = useStaticQuery(graphql
    `query{
        allMicrocmsRestaurantPlanRph (sort: {fields: sortIndex, order: ASC}){
          edges {
            node {
              description
              eventclass
              fee
              note
              purpose
              pickup
              restaurantPlanRphId
              restaurants
              title
              eyecatch {
                height
                url
                width
              }
              link {
                blank
                href
              }
            }
          }
        }
      }`
  )
  const newsdata = newsdataquery.allMicrocmsRestaurantPlanRph.edges
  return newsdata
}

export default restaurantPlanGet