import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CInlineDefinition,
  CHeroSlider,
  CDefinition,
  CRestaurantSearch,
  CNewsList,
  CBtnList,
  CRestaurantCard,
  CVerticalMedia,
  CVerticalMedia02,
  CRestaurantPlanRecommend,
  CFooterRestrantSearch,
} from '../../components/_index';
import '../../assets/_sass/page/restaurants.scss';
import infoChoice from '../../utils/info-choice';
import infoGet from '../../utils/info-get';
import restaurantPlanChoice from '../../utils/restaurantPlan-choice';
import restaurantPlanGet from '../../utils/restaurantPlan-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const [modalFlag, setModalFlag] = useState(false);
  const openModal = (e: any) => {
    e.stopPropagation();
    setModalFlag(!modalFlag);
  };
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: 'RESTAURANT&BAR',
            sub: <>レストラン&バー</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <CRestaurantSearch formId="restaurant" />
      <div className="u_pt80">
        <LWrap>
          <CSectTitle
            title={{
              en: 'INFORMATION',
              ja: 'お知らせ',
            }}
          />
          <CNewsList
            data={infoChoice(infoGet(), ['レストラン&バー'], 5)}
            label={false}
          />
          <CBtnList
            data={[
              {
                label: 'お知らせ一覧',
                // size: 'large',
                link: {
                  href: '/information/',
                },
                color: 'borderBlack',
              },
            ]}
          />
        </LWrap>
      </div>
      <section className="l_sect02 u_pb50_sp">
        <LWrap>
          <CSectTitle
            title={{
              en: 'RESTAURANT & BAR',
              ja: '店舗一覧',
            }}
          />
          <CRestaurantCard
            data={[
              {
                link: {
                  href: '/restaurants/sumida/',
                },
                img: {
                  src: '/assets/images/restaurants/img_store.png',
                  alt: '',
                },
                title: '鉄板焼 すみだ',
                floor: '20F',
                label: '個室あり',
              },
              {
                link: {
                  href: '/restaurants/genjikoh/',
                },
                img: {
                  src: '/assets/images/restaurants/img_store02.png',
                  alt: '',
                },
                title: '日本料理 源氏香',
                floor: '5F',
                label: '個室あり',
              },
              {
                link: {
                  href: '/restaurants/kei_ka_en/',
                },
                img: {
                  src: '/assets/images/restaurants/img_store03.png',
                  alt: '',
                },
                title: '中国料理 桂花苑',
                floor: 'B1F',
                label: '個室あり',
              },
              {
                link: {
                  href: '/restaurants/symphony/',
                },
                img: {
                  src: '/assets/images/restaurants/img_store04.png',
                  alt: '',
                },
                title: 'シェフズダイニング シンフォニー',
                floor: '1F',
              },
              {
                link: {
                  href: '/restaurants/fontaine/',
                },
                img: {
                  src: '/assets/images/restaurants/img_store05.png',
                  alt: '',
                },
                title: 'ロビーラウンジ フォンテーヌ',
                floor: '1F',
              },
              {
                link: {
                  href: '/restaurants/yanone/',
                },
                img: {
                  src: '/assets/images/restaurants/img_store06.png',
                  alt: '',
                },
                title: 'すし処 日本橋 矢の根寿司',
                floor: 'B1F',
                label: '個室あり',
              },
              {
                link: {
                  href: '/restaurants/royal_scots/',
                },
                img: {
                  src: '/assets/images/restaurants/img_store07.png',
                  alt: '',
                },
                title: 'メインバー ロイヤルスコッツ',
                floor: 'B1F',
                label: '個室あり',
              },
              {
                link: {
                  href: '/restaurants/iki/',
                },
                img: {
                  src: '/assets/images/restaurants/img_store08.png',
                  alt: '',
                },
                title: 'スイーツ&ベーカリー 粋',
                floor: '1F',
              },
            ]}
          />
        </LWrap>
      </section>
      <section
        className="l_sect04 p_restaurantsBgWrapper u_mb0"
        style={{ overflow: 'hidden' }}
      >
        <LWrap>
          <CVerticalMedia02
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/img_case.png',
                },
                title: {
                  en: 'PRIVATE ROOM',
                  ja: '個 室',
                },
                catchCopy: {
                  title: <></>,
                  text: (
                    <>
                      プライベート空間で過ごす
                      <br />
                      大切なひととき
                    </>
                  ),
                },
                topLink: {
                  label: 'レストラン個室のご案内',
                  link: {
                    href: '/restaurants/private/',
                  },
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/img_case02.png',
                },
                title: {
                  en: 'CELEBRATION',
                  ja: 'お 祝 い',
                },
                catchCopy: {
                  title: <></>,
                  text: (
                    <>
                      特別な日に特別な料理を
                      <br />
                      みなさまの思い出とともに
                    </>
                  ),
                },
                topLink: {
                  label: 'お祝い',
                  link: {
                    href: '/oiwai/',
                  },
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/img_case03.png',
                },
                title: {
                  en: 'ANNIVERSARY',
                  ja: '記 念 日',
                },
                catchCopy: {
                  title: <></>,
                  text: (
                    <>
                      心を込めておもてなしを
                      <br />
                      記念日に欠かせない
                      <br />
                      サプライズのお手伝い
                    </>
                  ),
                },
                topLink: {
                  label: '記念日・プロポーズ',
                  link: {
                    href: '/anniversary/',
                  },
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/img_case04.png',
                },
                title: {
                  en: 'MEMORIAL SERVICE',
                  ja: '法 要',
                },
                catchCopy: {
                  title: <></>,
                  text: (
                    <>
                      懐かしい思い出を語り合う
                      <br />
                      温かな時間
                    </>
                  ),
                },
                topLink: {
                  label: '法要のご案内',
                  link: {
                    href: '/restaurants/memorial/',
                  },
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="l_sect02 u_bgGray" style={{ overflow: 'hidden' }}>
        <LWrap>
          <CSectTitle
            title={{
              en: 'MENU',
              ja: 'おすすめメニュー',
            }}
          />
          <CRestaurantPlanRecommend
            data={restaurantPlanChoice(restaurantPlanGet(), [], 0)}
          />
          <CBtnList
            data={[
              {
                label: '他のプランを見る',
                // size: 'large',
                link: {
                  href: '/restaurants/plan/',
                },
                color: 'bgWhite03',
              },
            ]}
          />
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'レストラン&バー',
              path: '/restaurants/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />

      <CFooterRestrantSearch formId="footer" />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
